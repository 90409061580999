import React, { useState, useEffect } from 'react';
import { ScheduleComponent, ViewsDirective, ViewDirective, Day, WorkWeek, Month, Agenda, Inject, Resize, DragAndDrop } from '@syncfusion/ej2-react-schedule';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { L10n, loadCldr } from '@syncfusion/ej2-base';
import axios from 'axios';
import DetalleInstalacion from '../components/DetalleInstalacion';
import numberingSystems from '@syncfusion/ej2-cldr-data/supplemental/numberingSystems.json';
import caGregorian from '@syncfusion/ej2-cldr-data/main/es/ca-gregorian.json';
import numbers from '@syncfusion/ej2-cldr-data/main/es/numbers.json';
import timeZoneNames from '@syncfusion/ej2-cldr-data/main/es/timeZoneNames.json';


loadCldr(numberingSystems, caGregorian, numbers, timeZoneNames);

L10n.load({
  'es': {
    'schedule': {
      'day': 'Día',
      'workWeek': 'Semana laboral',
      'month': 'Mes',
      'agenda': 'Agenda',
      'today': 'Hoy',
      'noEvents': 'No hay eventos',
      'allDay': 'Todo el día',
      'start': 'Inicio',
      'end': 'Fin',
      'more': 'más',
      'close': 'Cerrar',
    },
    'datepicker': {
      'placeholder': 'Fecha actual',
      'today': 'Hoy',
    }
  }
});

const eventTemplate = (props) => {
  const startTime = new Date(props.StartTime).toLocaleTimeString('es-CL', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false, // IMPORTANTE: Esto fuerza el formato 24 horas
  });

  const endTime = new Date(props.EndTime).toLocaleTimeString('es-CL', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
return (
    <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
      <p style={{ margin: 0 }}>{props.Subject}</p>
      <div style={{ fontSize: '12px' }}>
        {startTime} - {endTime}
      </div>
      <p style={{ margin: 0, fontSize: '11px' }}>{props.Location}</p>
      <p style={{ margin: 0, fontSize: '11px'}}>{props.Description}</p>
      <p style={{ margin: 0, fontSize: '11px', fontStyle: 'italic' }}>{props.Vehicle}</p>
    </div>
  );
};

const Scheduler = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [scheduleData, setScheduleData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedInstalacionId, setSelectedInstalacionId] = useState(null);
  const [detalleVisible, setDetalleVisible] = useState(false);
  const isSmallScreen = window.innerWidth <= 768;


  const handlePopupOpen = async (args) => {
    args.cancel = true; // Evita el popup azul
    const ordenId = args.data.Order;
  
    if (ordenId) {
      try {
        const token = localStorage.getItem('access_token');
        if (!token) throw new Error('No se ha encontrado el token de autenticación.');
  
        // Llamar a la API para obtener el id_instalacion desde el ordenId
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/instalacion/instalaciones/por-orden/${ordenId}/`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
  
        if (response.data) {
          setSelectedInstalacionId(response.data); // Guardar el ID de instalación
          setDetalleVisible(true);
        } else {
          console.warn("⚠️ No se encontró instalación para la orden.");
          alert("No se encontró una instalación asociada a esta orden.");
        }
      } catch (error) {
        console.error("❌ Error obteniendo la instalación:", error);
  
        if (error.response) {
          console.error("⚠️ Respuesta del servidor:", error.response.data);
        }
  
        alert("No se pudo recuperar la instalación asociada a esta orden.");
      }
    }
  };
  


  const closeDetalle = () => {
    setDetalleVisible(false);
    setSelectedInstalacionId(null);
  };


  const adjustDateIfWeekend = (date) => {
    const day = date.getDay();
    if (day === 0) return new Date(date.setDate(date.getDate() + 1));
    if (day === 6) return new Date(date.setDate(date.getDate() + 2));
    return date;
  };

  const fetchEvents = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem('access_token');
      if (!token) throw new Error('No se ha encontrado el token de autenticación.');

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/events/events/`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response && response.data) {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Importante: Limpiar horas
        setScheduleData(
          response.data.map(event => {
            const startTime = new Date(event.start_time);
            const isPastEvent = startTime < today;
            return {
              Id: event.id,
              Subject: event.subject,
              Location: event.location,
              StartTime: startTime,
              EndTime: new Date(event.end_time),
              CategoryColor: isPastEvent ? '#4CAF50' : event.category_color, // Verde si es pasado
              Description: event.description,
              Vehicle: event.vehicle,
              Order: event.order,
            };
          })
        );
      } else {
        throw new Error('No se recibieron datos del servidor.');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSelectedDate(adjustDateIfWeekend(new Date()));
    fetchEvents();
  }, []);

  const handleEventRendered = (args) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const eventStart = new Date(args.data.StartTime);

  if (eventStart < today) {
    args.element.style.backgroundColor = '#4CAF50'; // Cambia el fondo

  }
};

  const schedulerStyles = {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    backgroundColor: 'white',
    borderRadius: '12px',
    padding: '16px',
  };
  return (
    <div style={{ height: '120vh', display: 'flex', flexDirection: 'column' }}>
      {isLoading ? (
        <p>Cargando eventos...</p>
      ) : error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : (
        <ScheduleComponent
          popupOpen={handlePopupOpen}
          style={schedulerStyles}
          selectedDate={selectedDate}
          eventRendered={handleEventRendered}
          eventSettings={{ dataSource: scheduleData, allowEditing: false,
          template: eventTemplate,
           }}  // Desactivar edición
          dragStart={(arg) => { arg.navigation.enable = true; }}
          startHour="09:00"
          endHour="19:00"
          firstDayOfWeek={1}
          locale="es"
          currentView={isSmallScreen ? 'Agenda' : 'WorkWeek'}
          timezone="America/Santiago"
          workDays={[1, 2, 3, 4, 5, 6]}
          timeScale={{
            interval: 120, // Duración de cada bloque en minutos (60 para una hora completa)
            slotCount: 2, // Número de divisiones por intervalo (1 para una sola línea por hora)
          }}
        >
          <ViewsDirective>
            <ViewDirective option="Day" />
            <ViewDirective option="WorkWeek" />
            <ViewDirective option="Month" showWeekend={false} />
            <ViewDirective option="Agenda" />
          </ViewsDirective>
          <Inject services={[Day, WorkWeek, Month, Agenda, Resize, DragAndDrop]} />
        </ScheduleComponent>
      )}
{detalleVisible && selectedInstalacionId && (
  <DetalleInstalacion
    instalacion={selectedInstalacionId}  // Pasamos el ID correctamente
    onClose={closeDetalle}
  />
)}

      <div className="mt-5">
        <DatePickerComponent
          value={selectedDate}
          showClearButton={false}
          placeholder="Fecha actual"
          floatLabelType="Always"
          change={(args) => setSelectedDate(adjustDateIfWeekend(args.value))}
          locale="es"
        />
      </div>
    </div>
  );
};

export default Scheduler;
